import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = ({ data }) => (
  <Layout>
    <SEO title="Hire me" />
    <section className="u-mb">
      <h1>Hire Me</h1>
      <div className="contact u-mb">
        <p>
          I am currently looking for a fresh challenge in a tech leadership
          role, developing products for web or mobile platforms. I believe that
          at the current stage of my career, I require good alignment on company
          mission and seniority, ideally a Technical Lead / Head of Engineering
          / CTO / Tech Co-founder role in Health, Education, Music or Tech for
          Good.
        </p>
        <a
          href="/cv/cv.pdf"
          target="_blank"
          className="c-btn c-btn--primary c-btn--small u-mb"
        >
          View my CV
        </a>
        <h4>Note on Recruiters</h4>
        <p className="u-mb">
          Am happy to hear from recruiters, but please have an actual role in
          mind when you call.
        </p>

        <div className="t-bold">
          <h3>Contact Me</h3>
          <p>The best ways to reach me are:</p>
          <a
            className="c-btn c-btn--primary u-mr u-pl- u-mr-"
            href="tel: +44 (0) 7979 770053"
          >
            <BackgroundImage
              fixed={data.phone.childImageSharp.fixed}
              style={{
                backgroundPosition: "left",
                backgroundSize: "contain",
                width: "inherit",
                textIndent: "36px",
                lineHeight: "32px"
              }}
            >
              Phone
            </BackgroundImage>
          </a>
          <a
            href="mailto:dan@dangarland.co.uk"
            className="c-btn c-btn--primary u-mr u-pl- u-mr-"
          >
            <BackgroundImage
              fixed={data.mail.childImageSharp.fixed}
              style={{
                backgroundPosition: "left",
                backgroundSize: "contain",
                width: "inherit",
                textIndent: "36px",
                lineHeight: "30px"
              }}
            >
              Email
            </BackgroundImage>
          </a>
        </div>
      </div>
    </section>
  </Layout>
)

export const pageQuery = graphql`
  query {
    phone: file(relativePath: { eq: "icons/icon_phone.png" }) {
      childImageSharp {
        fixed(height: 30, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mail: file(relativePath: { eq: "icons/icon_mail.png" }) {
      childImageSharp {
        fixed(height: 30, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Page
